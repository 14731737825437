import React from "react";
import { graphql } from "gatsby";
import Img from 'gatsby-image';

import Layout from "../components/layout";
import SEO from "../components/seo";
import { HalfCol, Button } from '../components/common';

import '../styles/index.css';

const CTAForm = () => (
  <>
    <h1 className="text-2xl mt-4 mb-1">Join the shopper's revolution</h1>
    <p className="mb-3">
      Be notified when we go live!
    </p>
    <input type="text" placeholder="you@domain.com" className="shadow appearance-none rounded w-full py-1 px-2 mb-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"></input>
    <div className="block container my-3">
      <Button to="thanks" className="mt-3 max-w-xs">Let's get started</Button>
    </div>
  </>
);

const SplashSection = ({ logo, mallImg }) => (
  <section className="bg-green-gradient py-20 px-5">
    <div className="flex flex-col mb-16 items-center">
      <Img className="mb-3 xl:w-2/5 w-3/5" fluid={logo.childImageSharp.fluid} />

      <div className="flex flex-col text-grey-lightest justify-end pb-4">
        <h1 className="text-5xl mt-3 font-serif">Shopping is a drag</h1>
        <h1 className="text-2xl mt-1 mb-3 font-serif italic">Let's make it fun again</h1>
      </div>
    </div>

    <div className="flex flex-col justify-center xl:flex-row">
      <HalfCol>
        <Img fluid={mallImg.childImageSharp.fluid} />
      </HalfCol>

      <HalfCol className="md:pt-3 xl:pt-0 justify-center">
        <CTAForm />
      </HalfCol>
    </div>
  </section>
);

const Section1 = ({ shopperImg }) => (
  <section className="flex flex-col justify-center xl:flex-row py-20 px-5">
    <HalfCol lightText={false}>
      <h1 className="text-5xl text-purple-dark">We're putting you in charge</h1>
      <p className="text-2xl py-3 font-serif text-purple-dark">
        We envision everyone as a curator
        assembling unique groups of
        your favorite products.
      </p>
      <p className="text-2xl py-3 font-serif text-purple-dark">
        Personalize them with your own style much like
        boutique stores of times past.
      </p>
    </HalfCol>

    <HalfCol className="justify-end">
      <Img fluid={shopperImg.childImageSharp.fluid} />
    </HalfCol>
  </section>
);

const Section2 = ({ shopperImg }) => (
  <section className="bg-blue-gradient flex flex-col justify-center xl:flex-row py-20 px-5">
    <HalfCol className="justify-end xl:pb-0 md:pb-6 sm:pb-6">
      <Img fluid={shopperImg.childImageSharp.fluid} />
    </HalfCol>

    <HalfCol>
      <h1 className="text-5xl">It's all about <em>YOU</em></h1>
      <p className="text-2xl pt-8 pb-1 font-serif">
        Imagine creating <em>YOUR</em> own store...
      </p>
      <p className="text-2xl py-1 font-serif">
        With items <em>YOU </em> choose...
      </p>
      <p className="text-2xl py-1 font-serif">
        Packaged in an
        environment to suit <em>YOUR</em> tastes
      </p>
    </HalfCol>
  </section>
);

const Section3 = ({ shopperImg }) => (
  <section className="flex flex-col justify-center xl:flex-row py-20 px-5">
    <HalfCol lightText={false}>
      <h1 className="text-5xl text-purple-dark">Now imagine yourself</h1>
      <p className="text-2xl pt-3 pb-1 font-serif text-purple-dark">
        Share your collections with friends and family
      </p>
      <p className="text-2xl py-1 font-serif text-purple-dark">
        Get rewarded for purchases and impressions
      </p>
      <p className="text-2xl py-1 sm:pb-3 font-serif text-purple-dark">
        Sounds awesome, doesn't it?
      </p>
    </HalfCol>

    <HalfCol className="justify-end xl:pb-0 md:pb-6 sm:pb-6">
      <Img fluid={shopperImg.childImageSharp.fluid} />
    </HalfCol>
  </section>
);

const Section4 = ({ shopperImg }) => (
  <section className="bg-pink-gradient flex flex-col justify-center xl:flex-row py-20 px-5">
    <HalfCol>
      <h1 className="text-5xl">Are you ready?</h1>
      <CTAForm />
    </HalfCol>
  </section>
);


const IndexPage = ({ data }) => (
  <Layout className="index-layout">
    <SEO title="Home" keywords={[`hipstu`, `collection`, `shopping`]} />
    <SplashSection logo={data.whiteLogo} mallImg={data.mall2} />
    <Section1 shopperImg={data.shopper1} />
    <Section2 shopperImg={data.shopper2} />
    <Section3 shopperImg={data.shopper3} />
    <Section4 />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    whiteLogo: file(relativePath: { eq: "White_Logo@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shopper1: file(relativePath: { eq: "shopper1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shopper2: file(relativePath: { eq: "shopper2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shopper3: file(relativePath: { eq: "shopper3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mall2: file(relativePath: { eq: "mall2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
