/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";

import '../styles/layout.css';
import '../styles/theme.css';

import PrivacyPolicy from '../docs/privacy-policy.pdf';
import TOS from '../docs/tos.pdf';

const Layout = ({ children, withStyles, withHeader }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      {
        withHeader &&
          <Header siteTitle={data.site.siteMetadata.title} />
      }
        <div
          style={withStyles && {
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
          className="font-sans"
        >
          <main>{children}</main>
          <footer className="sticky pin-b flex justify-end pb-3 px-3 text-center">
            <a className="pr-4 text-purple hover:text-purple-dark" href={TOS}>Terms of Service</a>
            <a className="pr-4 text-purple hover:text-purple-dark" href={PrivacyPolicy}>Privacy Policy</a>
            <h4 style={{ display: `inline-block` }}>© {new Date().getFullYear()}, Built by <em>Vasaship</em></h4>
          </footer>
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withStyles: PropTypes.bool,
  withHeader: PropTypes.bool,
};

export default Layout;
