import React from "react";
import { Link } from "gatsby";

export const HalfCol = ({ children, className = '', lightText = true }) => (
  <div className={`${lightText ? 'text-grey-lightest' : 'text-grey-darkest' } flex flex-col xl:w-1/3 px-5 ${className}`}>
    { children }
  </div>
);

export const Button = ({ to, children, className }) => (
  <div className={`bg-purple rounded hover:bg-purple inline py-2 px-4 ${className}`}>
    <Link to={to} className="no-underline text-grey-lightest">
      { children }
    </Link>
  </div>
);
